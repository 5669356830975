import { Link } from 'react-router-dom';

export default function About() {

  const buetLink = "https://www.buet.ac.bd";
  return (
    <div id="about" className="container-fluid">
      <div className="row" align="justify">
        <div>
          <h2>About Us</h2>
          <h4>
            Bangladesh University of Engineering and Technology{" "}
            <Link target={`_blank`} to={buetLink}>
              (BUET)
            </Link>{" "}
            is a pioneer in engineering education in Bangladesh. The history of
            BUET dates back to 1876 when the Dhaka Survey School was founded.
            Later in 1947, it became an engineering college and gained
            independent university status in 1962. It is located on a compact
            83.9-acre land in the heart of Dhaka, the capital of Bangladesh.
            <br />
            <br />
            BUET offers high-quality research and education through its 18
            departments under five faculties which are architecture and
            planning, civil engineering, engineering, electrical and electronic
            engineering, and mechanical engineering. Six university-led
            institutes also focus on diverse areas of research. BUET has over
            600 faculty members and over 10000 undergraduate and graduate
            students. Graduates from BUET have a long history of enjoying
            extraordinary success all over the world, with many notable alumni
            serving as the global leaders in their respective fields.
            <br />
            <br />
            Excellent academic environment, state-of-the-art research
            facilities, and a vibrant campus life have made BUET the first
            choice for future engineers and architects in Bangladesh. Students
            are enrolled at BUET through a highly-competitive entrance
            examination. BUET is the technological research and innovation hub
            in Bangladesh. It has been contributing immensely to nation-building
            since its establishment by providing expert services to hallmark
            infrastructure development projects. Faculty members work closely
            with industry and the Government to address crucial problems and
            formulate national policies and strategies in various sectors. BUET
            is an active member of the Association of Commonwealth Universities
            and maintains strong collaborations with hundreds of renowned
            universities and industries at home and abroad.
            <br />
            <br />
          </h4>
        </div>
      </div>
    </div>
  );
}
