import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-top footer-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact"></div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BUET Home
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/notice/0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Tender Notice
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/facultyStaff"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Faculty and Staff
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/hNewsIndex"
                      target="_blank"
                      rel="noreferrer"
                    >
                      News and Events
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="http://biis.buet.ac.bd/BIIS_WEB/Login.do"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BIIS
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="http://file.biis.buet.ac.bd:8080/FileTracker/req"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Online Requisition
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links ">
                <h4>Offices of BUET</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/vcOffice"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vice-Chancellor’s Office
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/proVcOffice"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pro-Vice-Chancellor’s Office
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="http://regoffice.buet.ac.bd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Registrar Office
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/compOff"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Comptroller Office
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/engOff"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Engineering Office
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to="https://www.buet.ac.bd/web/#/daers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      DAERs Office
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="credits">
              Developed by{" "}
              <Link to="https://ictcell.buet.ac.bd/" style={{ color: "white" }}>
                Ict Cell, BUET
              </Link>
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <Link to="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </Link>
            <Link to="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </Link>
            <Link to="#" className="instagram">
              <i className="bx bxl-instagram"></i>
            </Link>
            <Link to="#" className="google-plus">
              <i className="bx bxl-skype"></i>
            </Link>
            <Link to="#" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </Link>
          </div>
        </div>
      </footer>
      <Link
        to="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </Link>
    </>
  );
}
