import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SharedLayout = () => {
  return (
    <>
      <Header />
      <main>
        <section>
          <div className="container containerBreak">
            <div className="d-flex justify-content-center">
              <div>
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default SharedLayout;
