import image1 from "../assets/img/personnel/Prof. Dr. Khan Mahmud Amanat.jpg";
import image2 from "../assets/img/personnel/Md. Sumon Rahman.jpg";
import image3 from "../assets/img/personnel/Md. Anowar Zahid.jpg";
import image4 from "../assets/img/personnel/Md. Shamiul Alam.jpg";
import image5 from "../assets/img/personnel/no-image.jpg";
import image6 from "../assets/img/personnel/AD-Kamruzzaman.jpg";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const personell = [
  {
    id: 1,
    name: "Md. Sumon Rahman",
    post: "Deputiy Director",
    email: "ddpnd@pnd.buet.ac.bd",
    image: image2,
  },

  {
    id: 3,
    name: "Mohammed Kamruzzaman",
    post: "Assistant Director (Planning)",
    email: "adppnd@pnd.buet.ac.bd",
    image: image6,
  },
  {
    id: 4,
    name: "Md. Shamiul Alam",
    post: "Accounts Officer",
    email: "aopnd@pnd.buet.ac.bd",
    image: image4,
  },
  {
    id: 5,
    name: "",
    post: "Procurement Officer",
    email: "dirpnd@pnd.buet.ac.bd",
    image: image5,
  },
];

export default function Personnel() {
  return (
    <>
      <section id="team" className="team section-bg">
        <div className="container">
          <div
            className="section-title aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2>
              <strong> Message From Director</strong>
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6  align-items-stretch">
              <div className="member aos-init aos-animate" data-aos="fade-up">
                <div className="member-img">
                  <br />
                  <img src={image1} className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Prof. Dr. Khan Mahmud Amanat </h4>
                  <span>
                    Director (Additional Charge) <br /> dirpnd@pnd.buet.ac.bd
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 d-flex align-items-stretch">
              <div
                className="member aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member-info">
                  <p>
                    {" "}
                    Our vision.......................................
                    .......................................................................
                    .....................................................
                    .............................................
                    .......................
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us" className="about-us">
        <Container>
          <Row className="justify-content-md-center">
            {personell.map((person) => (
              <>
                <Col md="auto" lg="3" style={{ padding: "10px" }}>
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          textAlign: "center",
                          paddingBottom: "15px",
                          color: "#65534c",
                        }}
                      >
                        {person.name}
                      </Card.Title>
                      <Card.Img variant="top" src={person.image} />
                      <Card.Text style={{ paddingTop: "15px" }}>
                        <strong>{person.post}</strong>
                        <br />
                        {person.email}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="auto"></Col>
              </>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
