import Carousel from "react-bootstrap/Carousel";
import {sliderImages}  from '../data'
import Img from "react-cool-img";
import { memo } from "react";

let Slider = () => {
  const slideStyle = {
    height: "500px",
  };

  return (
    <div className="container">
      <Carousel variant="light">
        {sliderImages.map((img) => {
          const {id, image} = img
          return (
            <Carousel.Item key={id}>
              <Img
                className="d-block w-100"
                style={slideStyle}
                src={image}
                alt="First slide"
              />
              <Carousel.Caption>
                <h5> </h5>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}

      </Carousel>
    </div>
  );
};

export default memo(Slider);
