import "./App.css";
import HomePage from "./pages/mainPages/HomePage";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Members from "./pages/mainPages/Members";
import AddressContact from "./pages/AddressContact";
import Services from "./pages/Services";
import About from "./pages/About";
import PreviousDirectors from "./pages/PreviousDirectors";
import SharedLayout from "./layouts/SharedLayout";

function App() {
  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" component={<SharedLayout />}>
              <Route index element={<HomePage />} />
              <Route path="member" element={<Members />} />
              <Route path="addressContact" element={<AddressContact />} />
              <Route path="services" element={<Services />} />
              <Route path="about" element={<About />} />
              <Route
                exact
                path="prevDirector"
                element={<PreviousDirectors />}
              />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
