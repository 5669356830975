export default function Services() {
  return (
    <>
      <br />
      <section id="services" className="services">
        <div className="container" align="justify">
          {/* <div classNameName="row">
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up">
                <div classNameName="icon">
                  <i classNameName="bi bi-briefcase"></i>
                </div>
                <h4 classNameName="title">
                  Advising
                </h4>
                <p classNameName="description">
                  Advising the university authority in planning new projects in
                  short and long terms.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div classNameName="icon">
                  <i classNameName="bi bi-card-checklist"></i>
                </div>
                <h4 classNameName="title">
                  Co-ordinate
                </h4>
                <p classNameName="description">
                  Co-ordinate and conduct meetings of the Planning and
                  Development Committee for policy/decision making as per the
                  directive of the university authority.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div classNameName="icon">
                  <i classNameName="bi bi-bar-chart"></i>
                </div>
                <h4 classNameName="title">
                  Guiding
                </h4>
                <p classNameName="description">
                  the Departments, Institutes, Offices and the Office of the
                  Chief Engineer of BUET for preparing Pre-DPP or DPP
                  (Development Project Proposal) to secure funds from GoB and
                  other funding agencies for new infrastructures and lab
                  facilities.
                </p>
              </div>
            </div>
            <div classNameName="col-md-12">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div classNameName="icon">
                  <i classNameName="bi bi-binoculars"></i>
                </div>
                <h4 classNameName="title">
                  Procurement
                </h4>
                <p classNameName="description">
                  The local and foreign procurements of equipment and goods for
                  all departments, laboratories and offices of BUET through e-GP
                  (electronic Government Procurement portal) or traditional
                  tendering are fully administered by the Directorate (P&D) in
                  accordance with the PPR (Public Procurement Regulation/Act).
                </p>
                <p>
                  The sources of these procurements are the recurring fund,
                  BUET’s own fund, special and development funds received from
                  GoB, World Bank loan for HEQEP (Higher Education Quality
                  Enhancement Project) etc.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div classNameName="icon">
                  <i classNameName="bi bi-binoculars"></i>
                </div>
                <h4 classNameName="title">
                  Creating
                </h4>
                <p classNameName="description">
                  Creating e-GP accounts for all the users (Heads, Directors,
                  TEC/TSC members, Sub Project Managers under HEQEP) within BUET
                  and for those tenders which are to be administered on-line.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div classNameName="icon">
                  <i classNameName="bi bi-brightness-high"></i>
                </div>
                <h4 classNameName="title">

                </h4>
                <p classNameName="description">
                  Liaising with various government agencies such as the UGC,
                  Ministry of Education, Ministry of Planning etc., preparations
                  of reports for these agencies for appraisal on the progress of
                  on-going development projects.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div classNameName="icon">
                  <i classNameName="bi bi-calendar4-week"></i>
                </div>
                <h4 classNameName="title">
                  Receiving
                </h4>
                <p classNameName="description">
                  the instruments (cheques) for allocated funds from UGC and
                  handing over to Comptroller of BUET.
                </p>
              </div>
            </div>

            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div classNameName="icon">
                  <i classNameName="bi bi-card-checklist"></i>
                </div>
                <h4 classNameName="title">
                  Advising
                </h4>
                <p classNameName="description">
                  Advising Comptroller of BUET in releasing the bills, tender
                  security and performance security related to the procurements
                  of equipment.
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div classNameName="icon">
                  <i classNameName="bi bi-bar-chart"></i>
                </div>
                <h4 classNameName="title">
                  Assisting
                </h4>
                <p classNameName="description">
                  Assisting the internal committees such as Master Plan
                  Committee, Syndicate etc. in policy/decision making on general
                  planning and development of BUET
                </p>
              </div>
            </div>
            <div classNameName="col-lg-4 col-md-6">
              <div classNameName="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div classNameName="icon">
                  <i classNameName="bi bi-bar-chart"></i>
                </div>
                <h4 classNameName="title">
                  Development Funds
                </h4>
                <p classNameName="description">
                  Liaising with the Office of the Chief Engineer responsible
                  for constructing the physical infrastructures under
                  development funds
                </p>
              </div>
            </div>
          </div> */}
          <h2 align="center">
            <span>Our Job</span>
          </h2>
          <br />
          <ul className="list-group">
            <li className="list-group-item">
              {" "}
              Advising the university authority in planning new projects in
              short and long terms.
            </li>
            <li className="list-group-item">
              Co-ordinate and conduct meetings of the Planning and Development
              Committee for policy/decision making as per the directive of the
              university authorit
            </li>
            <li className="list-group-item">
              {" "}
              Guiding the Departments, Institutes, Offices and the Office of the
              Chief Engineer of BUET for preparing Pre-DPP or DPP (Development
              Project Proposal) to secure funds from GoB and other funding
              agencies for new infrastructures and lab facilities
            </li>
            <li className="list-group-item">
              The local and foreign procurements of equipment and goods for all
              departments, laboratories and offices of BUET through e-GP
              (electronic Government Procurement portal) or traditional
              tendering are fully administered by the Directorate (P&D) in
              accordance with the PPR (Public Procurement Regulation/Act).
              <br />
              <br />
              The sources of these procurements are the recurring fund, BUET’s
              own fund, special and development funds received from GoB, World
              Bank loan for HEQEP (Higher Education Quality Enhancement Project)
              etc.
              <br />
              <br />
              The administration process includes preparing templates for bid
              documents, a review of legal and technical matters, inviting
              tenders, receiving tenders, review of the evaluations by the
              entities for quality control, awarding to the successful bidder,
              making contracts, assisting the entities in receiving goods from
              supplier/C&F agents, etc. and getting approval from
              Vice-Chancellor at every step.
            </li>
            <li className="list-group-item">
              Creating e-GP accounts for all the users (Heads, Directors,
              TEC/TSC members, Sub Project Managers under HEQEP) within BUET and
              for those tenders which are to be administered on-line.
            </li>
            <li className="list-group-item">
              Liaising with various government agencies such as the UGC,
              Ministry of Education, Ministry of Planning etc., preparations of
              reports for these agencies for appraisal on the progress of
              on-going development projects.
            </li>
            <li className="list-group-item">
              Receiving the instruments (cheques) for allocated funds from UGC
              and handing over to Comptroller of BUET.
            </li>
            <li className="list-group-item">
              Advising Comptroller of BUET in releasing the bills, tender
              security and performance security related to the procurements of
              equipment
            </li>
            <li className="list-group-item">
              Assisting the internal committees such as Master Plan Committee,
              Syndicate etc. in policy/decision making on general planning and
              development of BUET.
            </li>
            <li className="list-group-item">
              {" "}
              Liaising with the Office of the Chief Engineer responsible for
              constructing the physical infrastructures under development funds.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
