import { previosuDirectors } from "../data";
import { memo } from "react";

const PreviousDirectors=()=> {

  return (
    <>
      <section id="portfolio" className="portfolio">
        <div className="container">
          <h3 align="center">Previous Director’s List </h3>
          <h5 align="center">Directorate of Planning and Development, BUET</h5>

          <br />
          <table className="table table-hover" align="center">
            <tbody>
              <tr>
                <th>SL No.</th>
                <th>Name of the Directors</th>
                <th>
                  Tenure
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>From</th>

                        <th>To</th>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
              {previosuDirectors.map((director) => {
                const { id, name, from, to } = director;
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>{from}</td>
                            <td>{to}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default memo(PreviousDirectors);
