import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <main>
        <div className="container containerBreak">{children}</div>
      </main>
      <Footer />
    </>
  );
}
