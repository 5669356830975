import image1 from "./assets/img/forSlide/a (1).jpeg";
import image2 from "./assets/img/forSlide/a (2).jpeg";
import image3 from "./assets/img/forSlide/a (3).jpeg";

export const sliderImages = [
  { id: 1, image: image1 },
  { id: 2, image: image2 },
  // { id: 3, image: image3 },
];

export const previosuDirectors = [
  {
    id: 1,
    name: "Prof. Dr. Sohrabuddin Ahmed",
    from: "18-11-1978",
    to: "15-02-1985",
  },
  {
    id: 2,
    name: "	Prof. Dr. Alamgir Mujibul Hoque",
    from: "16-02-1985",
    to: "31-10-1989",
  },
  {
    id: 3,
    name: "Prof. Dr. A. M. M. Safiullah",
    from: "01-11-1989",
    to: "11-08-1993",
  },
  {
    id: 4,
    name: "Prof. Dr. M. Feroz Ahmed",
    from: "12-08-1993",
    to: "06-02-1996",
  },
  {
    id: 5,
    name: "Prof. Dr. Md. Abdur Rouf",
    from: "07-02-1996",
    to: "01-08-1998",
  },
  {
    id: 6,
    name: "	Prof. Dr. Md. Mazharul Hoque",
    from: "02-08-1998",
    to: "11-01-2003",
  },
  {
    id: 7,
    name: "Prof. Dr. A. M. M. Taufiqul Anwar",
    from: "12-01-2003",
    to: "31-12-2005",
  },
  {
    id: 8,
    name: "Prof. Dr. Sk. Sekender Ali",
    from: "01-01-2006",
    to: "12-08-2008",
  },
  {
    id: 9,
    name: "Prof. Dr. Md. Zoynul Abedin",
    from: "13-08-2008",
    to: "05-10-2010",
  },
  {
    id: 10,
    name: "Prof. Dr. Md. Mujibur Rahman",
    from: "06-10-2010",
    to: "10-10-2011",
  },
  {
    id: 11,
    name: "Prof. Dr. Ishtiaque Ahmed",
    from: "11-10-2011",
    to: "31-05-2015",
  },
  {
    id: 12,
    name: "	Prof. Dr. Sarwar Jahan Md. Yasin",
    from: "01-06-2015",
    to: "02-06-2017",
  },
  {
    id: 13,
    name: "Prof. Dr. S. Shahnawaz Ahmed",
    from: "03-06-2017",
    to: "10-06-2018",
  },
  {
    id: 14,
    name: "Prof. Dr. Mohammad Jahangir Alam",
    from: "11-06-2018",
    to: "06-01-2019",
  },
  {
    id: 15,
    name: "Prof. Dr. Md. Maksud Helali",
    from: "07-01-2019",
    to: "22-01-2019",
  },
  {
    id: 16,
    name: "Prof. Dr. G M Tarekul Islam",
    from: "23-01-2019",
    to: "09-11-2021",
  },
];
