import React from "react";

import Slider from "../Slider";

export default function HomePage() {
  return (
    <>
      <br />
      <Slider />
      <section id="testimonials" className="testimonials">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="col-lg-9" data-aos="fade-up">
              <div className="testimonial-item">
                <h2>
                  Welcome to{" "}
                  <span>Directorate of Planning and Development</span>
                </h2>
                {/* <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Bangladesh University of Engineering and Technology (BUET) is
                  a pioneer in engineering education in Bangladesh. The history
                  of BUET dates back to 1876 when the Dhaka Survey School was
                  founded. Later in 1947, it became an engineering college and
                  gained independent university status in 1962. It is located on
                  a compact 83.9-acre land in the heart of Dhaka, the capital of
                  Bangladesh.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="services" className="services">
          <div className="container">
            <h2 align="center">Our Job</h2>
            <hr />
            <br />
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="icon-box" data-aos="fade-up">
                  <div className="icon">
                    <i className="bi bi-briefcase"></i>
                  </div>
                  <h4 className="title">Advising</h4>
                  <p className="description">
                    Advising the university authority in planning new projects
                    in short and long terms.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist"></i>
                  </div>
                  <h4 className="title">Co-ordinate</h4>
                  <p className="description">
                    Co-ordinate and conduct meetings of the Planning and
                    Development Committee for policy/decision making as per the
                    directive of the university authority.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-bar-chart"></i>
                  </div>
                  <h4 className="title">Guiding</h4>
                  <p className="description">
                    Guiding the Departments, Institutes, Offices and the Office
                    of the Chief Engineer of BUET for preparing Pre-DPP or DPP
                    (Development Project Proposal) to secure funds from GoB and
                    other funding agencies for new infrastructures and lab
                    facilities.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-binoculars"></i>
                  </div>
                  <h4 className="title">Creating</h4>
                  <p className="description">
                    Creating e-GP accounts for all the users (Heads, Directors,
                    TEC/TSC members, Sub Project Managers under HEQEP) within
                    BUET and for those tenders which are to be administered
                    on-line.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bi bi-brightness-high"></i>
                  </div>
                  <h4 className="title">Liaising</h4>
                  <p className="description">
                    Liaising with various government agencies such as the UGC,
                    Ministry of Education, Ministry of Planning etc.,
                    preparations of reports for these agencies for appraisal on
                    the progress of on-going development projects.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <i className="bi bi-calendar4-week"></i>
                  </div>
                  <h4 className="title">Receiving </h4>
                  <p className="description">
                    Receiving the instruments (cheques) for allocated funds from
                    UGC and handing over to Comptroller of BUET.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-left">
                <h3>
                  We've created more than <span>200</span> e-GP account
                </h3>
                <p>
                  Creating e-GP accounts for all the users (Heads, Directors,
                  TEC/TSC members, Sub Project Managers under HEQEP) within BUET
                  and for those tenders which are to be administered on-line.
                </p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <button type="button" className="btn btn-light">
                  <a href="mailto:someone@example.com">
                    Request for e-GP account
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
      </main>
    </>
  );
}
