import Personnel from "./../Personnel";

export default function Members() {
  return (
    <>
      <Personnel />
      {/* <PreviousDirectors /> */}
    </>
  );
}
