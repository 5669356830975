/* eslint-disable jsx-a11y/iframe-has-title */
export default function AddressContact() {
  return (
    <>
      <section id="portfolio" className="portfolio">
        <div className="container">
          <div className="text-center">
            <h5>Address and Contact:</h5>
          </div>
          <div className="row">
            <div className="col-sm-2 col-xs-12"></div>
            <div className="col-sm-8 col-xs-12">
              <div className="panel panel-default text-center">
                <div className="panel-heading">
                  <h2>Directorate of Planning and Development</h2>
                  <br></br>
                </div>
                <div className="panel-body">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        width="690"
                        height="389"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=pnd%20buet&t=&z=15&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                      <br />
                    </div>
                  </div>
                  <p>
                    <br />
                    <strong>
                      3rd Floor, Exam Building, BUET Phone: +8802223366413,
                      PABX: +880255167100, +880255167228~57, Ext: 7350
                    </strong>
                  </p>
                </div>
                <div className="panel-footer">
                  <h5>Email: dirpnd@pnd.buet.ac.bd, info@pnd.buet.ac.bd</h5>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-xs-12"></div>
          </div>
        </div>
      </section>
    </>
  );
}
